<template>
  <div class="content-wrap" style="background: #fff">
    <div class="wrap">
      <header class="pt-32">
        <img class="logo_img mb-16" src="@/assets/image/medal_logo_green.png" />
        <div class="fm-12 f-g40 mb-16">매달 파트너 가입 신청</div>
        <div class="navBar mb-32">
          <ul>
            <li class="ml-16 fm-14 nav-selected">1.개인 정보 입력</li>
            <li class="ml-16 fm-14">2.분야 입력</li>
            <li class="ml-16 fm-14">3.가입동의</li>
            <li class="ml-16 fm-14">4.가입신청 완료</li>
          </ul>
        </div>
      </header>
      <div class="contentBox">
        <div class="title_name mb-16">
          <span class="fm-16">파트너 가입자 휴대폰 인증</span>
          <p class="fr-12 mt-8">
            파트너 가입자의 가입승인 과정 및 서비스 이용안내를 위해 휴대폰
            연락처를 입력해주세요.
          </p>
          <p class="fr-12">
            입력하신 개인정보는 가입완료 전까지 저장되지 않습니다.
          </p>
        </div>
        <ul class="my_information">
          <li
            class="li-wrap"
            style="display: flex; border-bottom: 1px solid var(--gray20)"
          >
            <span class="address-title">휴대폰 번호</span>
            <div
              class="flex-space-between flex-align-center"
              style="padding-left: 16px; width: 100%"
            >
              <input type="text" :disabled="isCheck" style="width: 250px" v-model="phone" />
              <button-common
                :disabled="!checkPhoneValid || isCheck"
                :size="SIZE_T"
                :clr="CLR_G"
                style="width: 130px"
                @click="sendPhoneNum"
                >인증번호 요청</button-common
              >
            </div>
          </li>
          <li class="li-wrap">
            <span class="address-title">인증번호 입력</span>
            <div
              class="flex-space-between flex-align-center"
              style="width: 100%"
            >
              <span class="address-value"
                ><input type="text" style="width: 250px" v-model="phoneCheck"
              /></span>
              <div class="flex-align-center">
                <span
                  class="business-info"
                  style="color: #2ccdc3"
                  v-if="time !== 0 && isSend && !isCheck"
                  >{{ formatTime() }}</span
                >
                <span
                  class="business-info"
                  style="color: #fd765f"
                  v-if="time === 0 && isSend && !isCheck"
                  >인증번호를 재요청해주세요.</span
                >
                <span
                  class="business-info"
                  style="color: #2ccdc3; margin-left: 20px"
                  v-if="!isFirst && isCheck"
                  >인증완료</span
                >
                <span
                  class="business-info"
                  style="color: #fd765f; margin-left: 20px"
                  v-if="!isFirst && !isCheck && time !== 0"
                  >인증실패</span
                >
                <button-common
                  :disabled="!checkValid"
                  :size="SIZE_T"
                  :clr="CLR_G"
                  style="margin-left: 20px"
                  @click="checkPhoneNum"
                  >확인</button-common
                >
              </div>
            </div>
          </li>
        </ul>
        <div
          class="d-flex justify-content-center column"
          style="margin-top: 110px"
        >
          <div class="explanation-box fr-12 f-g60">
            입력하신 계좌번호는 가입승인 후 정산계좌로 자동등록되며 등록된
            파트너 정보는 변경이 가능합니다.
          </div>
        </div>
      </div>
    </div>
    <div class="next mb-80" style="margin-top: 62px">
      <button-common
        :size="SIZE_S"
        :clr="CLR_W"
        style="margin-right: 16px"
        @click="goBack()"
        >이전</button-common
      >
      <button-common
        :disabled="!isCheck"
        :size="SIZE_S"
        :clr="CLR_B"
        @click="regPhone()"
        >다음</button-common
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Phone",
  components: {},
  data() {
    return {
      phone: "",
      phoneCheck: "",
      isFirst: true,
      isSend: false,
      isCheck: false,
      time: 0,
      interval: null,
    };
  },
  computed: {
    checkValid() {
      return this.isSend && this.phoneCheck.length === 6 && !this.isCheck && this.time !== 0;
    },
    checkPhoneValid() {
      let regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      return regPhone.test(this.phone);
    },
  },
  methods: {
    async sendPhoneNum() {
      clearInterval(this.interval);
      this.isCheck = false;
      const rs = await this.$axios({
        url: "/partner/reg/certReq",
        params: { phone: this.phone },
      });
      this.isSend = rs.result === "SUC";
      if (rs.result === "SUC") {
        this.isFirst = true;
        this.setTimer();
      }
      if (rs.result === "DUPL") {
        await this.openDuplicate();
      }
    },
    async checkPhoneNum() {
      this.isFirst = false;
      const rs = await this.$axios({
        url: "/partner/reg/cert",
        params: { certNumber: this.phoneCheck },
      });
      this.isCheck = rs.result === "SUC";
      if (this.isCheck) {
        clearInterval(this.interval);
      }
      if (rs.result === "DUPL") {
        await this.openDuplicate();
      }
    },
    setTimer() {
      this.time = 180;
      this.interval = setInterval(() => {
        this.time = this.time - 1;
        if (this.time === 0) {
          clearInterval(this.interval);
        }
      }, 1000);
    },
    formatTime() {
      if (this.time === 0) {
        return "00:00";
      } else {
        let min = Math.floor(this.time / 60);
        let sec = this.time % 60;
        if (min < 10) {
          min = "0" + min;
        }
        if (sec < 10) {
          sec = "0" + sec;
        }
        return min + ":" + sec;
      }
    },
    async openDuplicate() {
      await this.$openDuplicate({}, this);
    },
    async regPhone() {
      const rs = await this.$axios({
        url: "/partner/reg/phone",
        params: { phone: this.phone },
      });
      if (rs.result === "SUC") {
        this.goNextPage();
      }
    },
    goNextPage() {
      this.$router.push({
        path: `/sign/email`,
        meta: { id: "0-1-4" },
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/join";
.li-wrap {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 12px;
}
.error {
  color: #f1431d;
  font-size: 14px;
}
.confirm {
  color: #2ec8b5;
  font-size: 14px;
}
.address-title {
  min-width: 200px;
  padding-left: 32px;
  font-size: 14px;
  font-weight: 500;
  color: #3a3d4d;
}
.address-value {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #696c80;
}
.business-info {
  font-weight: 500;
  font-size: 12px;
  color: #828699;
}
.div-circle {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #dddddd;
  margin: 0 2px;
}
</style>
